import { AuthError, useOpointAuth, useOpointAuthError, useOpointAuthState, useOpointUser } from '@opoint/authjs-react'
import { ElementType, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { isSettingsFetched } from '../../../selectors/settingsSelectors'
import Loader from '../../common/Loader'
import { isMobileDevice } from '../../helpers/ui'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import ServiceUnavailableErrorPage from '../../../pages/errors/ServiceUnvailableError'

type RequireAuthenticationProps = {
  as: ElementType
}

const RequireAuthentication = ({ as: Component }: RequireAuthenticationProps) => {
  const authUser = useOpointUser()
  const auth = useOpointAuth()
  const authState = useOpointAuthState()
  const error = useOpointAuthError()

  const settingsFetched = useAppSelector(isSettingsFetched)

  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    // Redirect to the login page if not authenticated
    if (authUser === null) {
      // @ts-expect-error: Muted so we could enable TS strict mode
      auth.login(`?redirect=${encodeURIComponent(location.pathname + location.search)}`)
    }
  }, [authUser, auth, location.pathname, location.search])

  useEffect(() => {
    if (!authUser?.user_id) {
      return
    }

    dispatch({ type: 'SEARCH_DATA_INIT' })
  }, [authUser?.user_id, dispatch])

  useEffect(() => {
    if (!authUser) {
      return
    }

    const isMobilePath = location.pathname.startsWith('/mobile')

    if (
      (isMobilePath && location.search.length === 0) ||
      (!isMobilePath && document.documentElement.clientWidth <= 1100 && isMobileDevice())
    ) {
      dispatch({ type: 'GO_TO_DEFAULT_PROFILE_MOBILE' })
    } else if (location.pathname === '/') {
      dispatch({ type: 'GO_TO_DEFAULT_PROFILE' })
    }
  }, [authUser, dispatch, location.pathname, location.search])

  if (error && (error instanceof AuthError || error instanceof TypeError) && authState === undefined) {
    return <ServiceUnavailableErrorPage statusCode={error instanceof AuthError ? error.context?.status ?? 500 : 500} />
  }

  if (!authUser || !settingsFetched) {
    return (
      <div className="flex w-screen flex-col items-center justify-center">
        <Loader />
      </div>
    )
  }

  return <Component />
}

export default RequireAuthentication
